import React, { useState, useEffect } from 'react';

import Container from '../Container/Container';
import ConstrutiveStep from './ConstrutiveStep';
import WorkKindStep from './WorkKindStep';
import UsageStep from './UsageStep';

import { IRequestData, ICurrentData } from './Types';

import './SolutionsQuiz.scss';
import BannerSlideEnum from '../../enums/banner-slide.enum';

const SolutionsQuiz: React.FC = (): JSX.Element => {
  const url = typeof window !== 'undefined' ? new URL(window.location.href) : undefined;
  const type: BannerSlideEnum = url?.searchParams.get('type') as BannerSlideEnum;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentData, setCurrentData] = useState<ICurrentData>({
    catalogData: null,
    constructiveTitle: type || '',
    workKindTitle: [],
    usage: null,
  });

  const updateCurrentData = (field: keyof ICurrentData, data: any) => {
    setCurrentData(prev => ({
      ...prev,
      [field]: data,
    }));
  };

  const currentStepHandler = (step: 'prev' | 'next') => {
    if (step === 'prev' && currentStep >= 0) {
      setCurrentStep(currentStep - 1);
    }
    if (step === 'next' && currentStep <= 3) {
      setCurrentStep(currentStep + 1);
    }
    document.getElementById('solutions-quiz__title').scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    fetch(process.env.CATALOG_API_URL).then(res => {
      res.json().then((data: IRequestData) => {
        updateCurrentData('catalogData', data.data);
        if (type) currentStepHandler('next');
      });
    });
  }, []);

  return (
    <div className="solutions-quiz">
      <Container className="solutions-container">
        <h1 className="solutions-quiz__title" id="solutions-quiz__title">
          Будівельні рішення
        </h1>
        {currentData.catalogData ? (
          <div className="solutions-quiz__wrapper" id="solutions-quiz__wrapper" style={{ left: `-${currentStep * 120}%` }}>
            <>
              <ConstrutiveStep currentData={currentData} stepHandler={currentStepHandler} setCurrentData={updateCurrentData} />
              <WorkKindStep currentData={currentData} stepHandler={currentStepHandler} setCurrentData={updateCurrentData} />
              <UsageStep currentData={currentData} stepHandler={currentStepHandler} setCurrentData={updateCurrentData} />
            </>
          </div>
        ) : (
          <div className="solutions-quiz__loader" />
        )}
      </Container>
    </div>
  );
};

export default SolutionsQuiz;
