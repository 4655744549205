import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql, useStaticQuery } from 'gatsby';

import { IStepProps, IConstructiveQuery } from './Types';

import './ConstrutiveStep.scss';

const query = graphql`
  {
    allCockpitQuizConstructive(filter: { lang: { eq: "ua" } }) {
      nodes {
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title {
          value
        }
      }
    }
  }
`;

const ConstrutiveStep: React.FC<IStepProps> = ({ setCurrentData, stepHandler }) => {
  const clickHandler = (constructiveTitle: string) => {
    stepHandler('next');
    setCurrentData('constructiveTitle', constructiveTitle);
  };

  const {
    allCockpitQuizConstructive: { nodes },
  } = useStaticQuery<IConstructiveQuery>(query);

  const constructiveData = nodes.map(({ title, image }) => {
    return {
      title: title.value,
      image: image.value.childImageSharp.fluid,
    };
  });

  return (
    <div className="constructive-step">
      <h2>Будівництво якої частини будинку вас цікавить?</h2>

      <div className="constructive-step__wrapper">
        {constructiveData.map(({ title, image }) => (
          <div className="constructive-step__item" onClick={() => clickHandler(title)} key={title}>
            <Img fluid={image} objectFit="contain" className="constructive-step__item-img" alt={title} />
            <p className="constructive-step__item__title">{title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConstrutiveStep;
