import React from 'react';
import { FormikValues } from 'formik';

import Container from '../Container/Container';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import { sendMessage } from '../../utils/sendMessage';

import './SolutionsForm.scss';

const SolutionsForm: React.FC = () => {
  const modal = (sucsess: boolean) => {};

  const onSubmit = (values: FormikValues) => {
    sendMessage(values, 'questions', modal);
  };
  return (
    <section className="solutions-form" id="solutions-form">
      <Container className="solutions-form__container">
        <div className="solutions-form__text">
          <h2>
            Важко зорієнтуватись
            <br /> в каталозі? Отримайте онлайн консультацію!
          </h2>
          <p>Наш експерт проконсультує вас, та підбере УСІ необхідні матеріали для вашого ремонту</p>
        </div>
        <FeedbackForm
          type="default"
          onSubmit={onSubmit}
          buttonText="Відправити"
          buttonColor="blue"
          checkmarkColor="blue"
          feedbackTypeColor="black"
          isResisable
        />
      </Container>
      <div className="blue-circle" />
    </section>
  );
};

export default SolutionsForm;
