import ChoiceIcon from '../assets/icons/howToUse/choice.svg';
import InformationIcon from '../assets/icons/howToUse/information.svg';
import CatalogueIcon from '../assets/icons/howToUse/catalogue.svg';
import QuestionsIcon from '../assets/icons/howToUse/questions.svg';

export default [
  {
    id: 1,
    Icon: QuestionsIcon,
    title: 'Ознайомтесь з питанням та відповідями',
  },
  {
    id: 2,
    Icon: InformationIcon,
    title: 'Для кожного пункту - є пояснення (в чому різниця і які переваги) ',
  },
  {
    id: 3,
    Icon: ChoiceIcon,
    title: 'Далі оберіть варіант, який вам підходить та продовжуйте відповідати на питання',
  },
  {
    id: 4,
    Icon: CatalogueIcon,
    title: 'В кінці - для вас сформується каталог тільки з необхідними товарами по категоріям',
  },
];
