/* eslint-disable */

import React, { useState, useMemo, FormEvent } from 'react';

import InfoIcon from '../../assets/icons/info.svg';
import Button from '../Button/Button';

import { IStepProps } from './Types';

import './WorkKindStep.scss';

const WorkKindStep: React.FC<IStepProps> = ({ currentData, setCurrentData, stepHandler }) => {
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const data = useMemo(() => currentData.catalogData.find(item => item.title === currentData.constructiveTitle), [currentData]);
  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentData('workKindTitle', selectedData);
    stepHandler('next');
  };
  const prevHandler = () => {
    setSelectedData([]);
    setCheckboxesChecked(false);
    setCurrentData('workKindTitle', []);
    stepHandler('prev');
  };

  const toggleData = (title: string) => {
    const idx = selectedData.indexOf(title);
    return idx === -1 ? setSelectedData(prev => [...prev, title]) : setSelectedData(prev => [...prev].filter(item => item !== title));
  };
  const setCheckboxesChecked = (checked: boolean) => {
    document.querySelectorAll<HTMLInputElement>('.work-kind-step .checkbox').forEach(checkbox => {
      checkbox.checked = checked;
    });
  };

  const selectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      e.target.checked = false;
      setCheckboxesChecked(false);
      setSelectedData([]);
    } else {
      document.querySelectorAll<HTMLInputElement>('.work-kind-step .checkbox').forEach(checkbox => {
        checkbox.checked = true;
        if (checkbox.value && selectedData.indexOf(checkbox.value) === -1) setSelectedData(prev => [...prev, checkbox.value as string]);
      });
    }
  };

  return (
    <div className="work-kind-step">
      {data ? (
        <>
          <h2 className="work-kind-step__title" onClick={() => prevHandler()}>
            {data.title}
          </h2>
          <form onSubmit={submitHandler} className="work-kind-step__wrapper">
            <div className="work-kind-step__items" role="group">
              <h2 className="work-kind-step__items-title">{data.title}</h2>
              {data.workKind.map(({ title, description }) => (
                <label className="work-kind-step__items-item" key={title}>
                  <input type="checkbox" name="usage" value={title} className="checkbox" onChange={() => toggleData(title)} />
                  <div className="work-kind-step__items-item-title">
                    {title}
                    <p>{description}</p>
                  </div>
                </label>
              ))}
              <label className="work-kind-step__items-item ">
                <input type="checkbox" name="usage" className="checkbox select-all" value="" onChange={selectAll} />
                Вибрати всі
              </label>
            </div>
            <div className="work-kind-step__control">
              <InfoIcon />
              Конструктор будівництва допоможе вам підібрати необхідні матеріали. Більший вибір для архітектурного вираження даху (фактури,
              форми, колір). Краща шумоізоляція. Може монтуватися на покрівлі
              <Button htmlType="submit" color="yellow" type="primary" disabled={selectedData.length === 0}>
                Далі
              </Button>
            </div>
          </form>
        </>
      ) : null}
    </div>
  );
};

export default WorkKindStep;
