import React from 'react';

import Seo from '../components/SEO/SEO';
import StepsSlider from '../components/StepsSlider/StepsSlider';

import { SEO_ITEMS } from '../constants/SEOItems';
import howToUseItems from '../constants/howToUseItems';
import ForWho from '../components/ForWho/ForWho';
import SolutionsForm from '../components/SolutionsForm/SolutionsForm';
import SolutionsQuiz from '../components/SolutionsQuiz/SolutionsQuiz';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Наші партнери',
    link: '/partners',
  },
];

const Solutions: React.FC = (): JSX.Element => (
  <>
    <Seo
      breadcrumbs={crumbs}
      description={SEO_ITEMS.defaultDescription}
      lang="ua"
      path="/solutions"
      title={SEO_ITEMS.solutionsPage.title}
    />
    <SolutionsQuiz />
    <StepsSlider title="Як користуватись? " items={howToUseItems} />
    <ForWho />
    <SolutionsForm />
  </>
);

export default Solutions;
