import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../Container/Container';

import './ForWho.scss';

const MENU_QUERY = graphql`
  {
    image: file(relativePath: { eq: "assets/images/for-who-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const ForWho: React.FC = (): JSX.Element => {
  const {
    image: {
      childImageSharp: { fluid: imgSrc },
    },
  } = useStaticQuery(MENU_QUERY);

  return (
    <section className="for-who">
      <Container className="for-who__container">
        <div className="for-who__items">
          <h2>Для тих, хто...</h2>

          <p>Шукає всі необхідні товари для окремої частини ремонту</p>
          <p>Не має професійних знань з будівництва</p>
          <p>Не має професійних знань з будівництва</p>
          <p>Кому потрібен каталог “під ключ”</p>
        </div>
        <Img fluid={imgSrc} className="for-who__image" />
      </Container>
      <div className="yellow-circle" />
      <div className="blue-circle" />
    </section>
  );
};

export default ForWho;
