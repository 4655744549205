/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { Link, navigate } from 'gatsby';

import InfoIcon from '../../assets/icons/info.svg';
import InfoBlueIcon from '../../assets/icons/info-blue.svg';
import Button from '../Button/Button';

import { IStepProps, ISelectedData, IUsage, IGroups } from './Types';

import './UsageStep.scss';

const UsageStep: React.FC<IStepProps> = ({ currentData, setCurrentData, stepHandler }) => {
  const [selectedData, setSelectedData] = useState<ISelectedData[]>([]);
  const [isControlOpened, setControlOpened] = useState<boolean>(false);
  const [isUsageSelected, setUsageSelected] = useState<boolean>(false);
  const [openedItems, setOpenedItems] = useState<string[]>([]);

  const data = useMemo(
    () =>
      currentData.catalogData
        .find(item => item.title === currentData.constructiveTitle)
        ?.workKind.filter(item => currentData.workKindTitle.indexOf(item.title) !== -1),
    [currentData]
  );
  const nextHandler = () => {
    setCurrentData('workKindTitle', selectedData);
    return navigate(`${selectedData[0].usage.groups[0].link}?solutions`, { state: { solutions: true } });
  };

  const prevHandler = () => {
    setSelectedData([]);
    setOpenedItems([]);
    setControlOpened(false);
    setUsageSelected(false);
    document.querySelectorAll<HTMLInputElement>('.usage-step .checkbox').forEach(item => (item.checked = false));
    stepHandler('prev');
  };

  const toggleOpened = (title: string) => {
    const idx = openedItems.indexOf(title);
    return idx === -1 ? setOpenedItems(prev => [...prev, title]) : setOpenedItems(prev => [...prev].filter(item => item !== title));
  };

  const editSelectedData = (title: string, usage: IUsage) => {
    if (selectedData && selectedData.length !== 0) {
      const idx = selectedData.findIndex(item => item.title === title);
      const usageToSet = selectedData[idx].usage && selectedData[idx].usage.title === usage.title ? null : usage;

      if (usageToSet === null) {
        setUsageSelected(false);
      }
      setSelectedData(prev => Object.assign([...prev], { [idx]: { ...prev[idx], usage: usageToSet } }));
    }
  };
  useEffect(() => {
    if (data && data.length !== 0 && selectedData.length === 0) {
      setSelectedData(
        [...data].map(({ title }) => {
          return { title, usage: null };
        })
      );
    }
    // if (currentData.workKindTitle.length === 0) {
    //   setSelectedData([]);
    //   setOpenedItems([]);
    //   setControlOpened(false);
    //   setUsageSelected(false);
    // }
  }, [data]);

  useEffect(() => {
    if (data && selectedData.length !== 0) {
      let selectedCount = 0;
      selectedData.forEach(({ usage }) => usage && selectedCount++);

      if (selectedCount < data.length) {
        if (data[selectedCount]) {
          if (data[selectedCount].usage.length <= 1) {
            editSelectedData(data[selectedCount].title, data[selectedCount].usage[0]);
          }
          if (data[selectedCount - 1]) {
            setOpenedItems([...openedItems].filter(item => item !== data[selectedCount - 1].title));
          }
          toggleOpened(data[selectedCount].title);
        }
      } else {
        if (typeof window !== 'undefined') {
          localStorage.setItem(
            'solutions',
            JSON.stringify({
              constructiveTitle: currentData.constructiveTitle,
              usage: [...selectedData].map(item => item.usage),
            })
          );
          if (document.querySelectorAll<HTMLDivElement>('.usage-step__items-item').length === 0) {
            navigate(`${selectedData[0].usage.groups[0].link}?solutions`, { state: { solutions: true } });
          }
          setUsageSelected(true);
        }
      }
    }
  }, [selectedData]);

  const control = useMemo(() => {
    const groupsNumber = isUsageSelected
      ? [...selectedData].reduce((prev: number, item) => item.usage && prev + item.usage?.groups.length, 0)
      : 0;
    const groupsToShow: IGroups[] = [];
    let groupWord: string = 'групу';
    const groupWordLastDigit = Number(groupsNumber.toString().slice(-1));

    [...selectedData].forEach(({ usage }) => usage && groupsToShow.push(...usage.groups));

    if ((groupWordLastDigit === 2 || groupWordLastDigit === 3 || groupWordLastDigit === 4) && (groupsNumber < 10 || groupsNumber > 20)) {
      groupWord = 'групи';
    } else if (groupWordLastDigit !== 1) {
      groupWord = 'груп';
    }

    return isUsageSelected ? (
      <div className="usage-step__control">
        <div className="usage-step__control-amount">
          Вибрано
          <p>
            <span>{groupsNumber}</span> {groupWord} матеріалів
          </p>
        </div>

        <div className={`usage-step__control-items ${isControlOpened ? 'opened' : ''}`}>
          {groupsToShow.map(
            ({ title, link, description }, idx) =>
              (isControlOpened || idx < 5) && (
                <div className="usage-step__control-item" key={idx + title}>
                  <Link to={`${link}`}>{title}</Link>
                  <div className="usage-step__control-description">
                    <InfoBlueIcon /> <p>{description}</p>
                  </div>
                </div>
              )
          )}
        </div>
        <div className="usage-step__control-show" hidden={isControlOpened || groupsNumber < 7} onClick={() => setControlOpened(true)}>
          Показати всі
        </div>
        <Button htmlType="button" color="yellow" type="primary" click={() => nextHandler()}>
          Показати матеріали
        </Button>
      </div>
    ) : (
      <div className="usage-step__control unselected">
        <InfoIcon />
        Конструктор будівництва допоможе вам підібрати необхідні матеріали. Більший вибір для архітектурного вираження даху (фактури, форми,
        колір). Краща шумоізоляція. Може монтуватися на покрівлі
      </div>
    );
  }, [isUsageSelected, selectedData, isControlOpened, data, openedItems]);

  return (
    <div className="usage-step">
      {data ? (
        <>
          <h2 className="usage-step__title" onClick={() => prevHandler()}>
            {currentData.constructiveTitle}
          </h2>
          <div className="usage-step__wrapper">
            <div className="usage-step__items" role="group">
              {data.map(({ title, usage }, idx) => {
                return usage.length > 1 ? (
                  <div className={`usage-step__items-item ${openedItems.indexOf(title) !== -1 ? 'opened' : ''} `} key={title + idx}>
                    <div
                      className={`usage-step__items-item-title ${usage.length <= 1 ? 'no-children' : ''}`}
                      onClick={() => toggleOpened(title)}
                    >
                      {title}
                    </div>
                    <div className="usage-step__items-subitems">
                      {usage.length > 1 &&
                        usage.map(({ title: subtitle, groups, description: subdescription, shortName }, subidx) => (
                          <div
                            className={`usage-step__items-subitems-subitem ${openedItems.indexOf(subtitle) !== -1 ? 'opened' : ''}`}
                            key={subtitle + subidx}
                            role="group"
                          >
                            <div className="usage-step__items-subitems-subitem-wrapper">
                              <input
                                type="radio"
                                name={`usage-${title}`}
                                value={subtitle}
                                className="checkbox"
                                onChange={() =>
                                  editSelectedData(title, { title: subtitle, groups, description: subdescription, shortName })
                                }
                              />
                              <div className="usage-step__items-subitems-subitem-title" onClick={() => toggleOpened(subtitle)}>
                                {subtitle}
                                <p>{subdescription}</p>
                              </div>
                            </div>

                            {groups.map(({ title: subsubtitle, description: subsubdescription }, subsubidx) => (
                              <div className="usage-step__items-subsubitems-subsubitem " key={subsubtitle + subsubidx}>
                                <div className="usage-step__items-subsubitems-subsubitem-title">
                                  {subsubtitle}
                                  <p>{subsubdescription}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
            {control}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UsageStep;
